import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NavigationFacadeService } from '../navigation/navigation-facade.service';

@Injectable({
  providedIn: 'root'
})
export class FrontpageFacadeService {

  private data$ = new BehaviorSubject<any>(null);
  private lang = this.navigationFacade.language;

  constructor(
    private router: Router,
    private http: HttpClient,
    private navigationFacade: NavigationFacadeService,
  ) { }

  get data() {
    return this.data$.asObservable();
  }

  fetchData() {
    this.lang.subscribe((language) => {
      this.http.get(`/api/frontpage${language}`).subscribe((res: any) => {
        this.data$.next(res);
      });
    })
  }
}
